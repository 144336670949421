import { Box, ChakraProps, colors, Text } from "@biblioteksentralen/react";
import { ReactNode } from "react";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { ResolvedEventSummary } from "./sanityQuery";

type Props = {
  event: Pick<ResolvedEventSummary, "eventStatus" | "registration">;
  fontSize?: ChakraProps["fontSize"];
};

export const EventStatusTitlePrefix = ({ event }: Props) => {
  const statusText = useEventStatusText(event);

  if (statusText) return <>{statusText.toUpperCase()} - </>;

  return null;
};

export const EventStatusBadge = ({ event, fontSize }: Props) => {
  const statusText = useEventStatusText(event);

  if (!statusText) return null;

  if (event.eventStatus === "cancelled")
    return (
      <Ribbon fontSize={fontSize} background={colors.statusRed} color="white">
        {statusText}
      </Ribbon>
    );

  return <Ribbon fontSize={fontSize}>{statusText}</Ribbon>;
};

const useEventStatusText = (event: Pick<ResolvedEventSummary, "eventStatus" | "registration">) => {
  const { eventStatus, registration } = event;
  const { t } = useTranslation();

  switch (eventStatus) {
    case "cancelled":
      return t("Avlyst");
    case "full":
      return registration === "ticket" ? t("Utsolgt") : t("Fullt");
    case "almostFull":
      return registration === "ticket" ? t("Snart utsolgt") : t("Snart fullt");
    default:
      return null;
  }
};

type RibbonProps = ChakraProps & { children: ReactNode };

const Ribbon = ({ children, ...chakraProps }: RibbonProps) => (
  <Box position="absolute" top="0" bottom="0" left="0" right="0" overflow="hidden" pointerEvents="none">
    <Text
      position="absolute"
      fontSize="2em"
      textAlign="center"
      right="0em"
      top="0"
      transform="rotate(45deg) translate(32.5%, -75%)"
      transformOrigin="center"
      fontWeight="600"
      paddingX="5em"
      whiteSpace="nowrap"
      background={colors.accentYellow}
      textTransform="uppercase"
      opacity={0.95}
      {...chakraProps}
    >
      {children}
    </Text>
  </Box>
);
